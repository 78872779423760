import { CircularProgress, Grid, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getLoadingBoxStyle: (
  windowHeight: number,
) => ComponentProps<typeof Grid> = (windowHeight) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: `${windowHeight - 50}px`, // subtracting the height of the TopBar, adjust accordingly
});

export const getCircularProgressStyle: () => ComponentProps<
  typeof CircularProgress
> = () => ({
  mb: '1.5rem',
  sx: {
    color: (theme) => theme.palette.grey[800],
  },
});

export const getLoadingTextStyle: () => ComponentProps<
  typeof Typography
> = () => ({
  variant: 'h6',
  fontFamily: 'Roboto',
  marginTop: '1rem',
  sx: {
    color: (theme) => theme.palette.grey[800],
  },
});
