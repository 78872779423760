import { queue } from '@hpx-it/queue-client';
import { logger } from '@hpx-it/react-app';
import { DeveloperApiClientContext } from '../developerApiClientContext';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type TimeslotContextProps = {
  availableTimeslots: queue.Availability[];
  numberOfTimeslots: number | undefined;
  canJoinQueue: boolean;
  loadingTimeslots: boolean;
  refetchTimeslots: () => void;
};

export type TimeslotProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: TimeslotContextProps = {
  availableTimeslots: [],
  numberOfTimeslots: undefined,
  canJoinQueue: false,
  loadingTimeslots: true,
  refetchTimeslots: () => {},
};

export const TimeslotContext =
  createContext<TimeslotContextProps>(DEFAULT_CONTEXT);

export const TimeslotProvider = ({ children }: TimeslotProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const [canJoinQueue, setCanJoinQueue] = useState<boolean>(false);
  const [availableTimeslots, setAvailableTimeslots] = useState<
    { start: Date; end: Date; count: number }[]
  >([]);
  const [numberOfTimeslots, setNumberOfTimeslots] = useState<number>();
  const [loadingTimeslots, setLoadingTimeslots] = useState<boolean>(true);

  const refetchTimeslots = useCallback(async () => {
    (async () => {
      try {
        const response = await getDeveloperApiClient().getAvailability();
        setCanJoinQueue(response.immediate);
        setAvailableTimeslots(
          response.timeslots.map((timeslot) => ({
            start: new Date(timeslot.start_time),
            end: new Date(timeslot.end_time),
            count: timeslot.count,
          })),
        );
        setNumberOfTimeslots(response.timeslots.length);
      } finally {
        setLoadingTimeslots(false);
      }
    })();
  }, [getDeveloperApiClient]);

  useEffect(() => {
    refetchTimeslots();
  }, [refetchTimeslots]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        logger.info('Refetching timeslots as resident re-focused on tab');
        refetchTimeslots();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetchTimeslots]);

  return (
    <TimeslotContext.Provider
      value={{
        availableTimeslots,
        numberOfTimeslots,
        canJoinQueue,
        loadingTimeslots,
        refetchTimeslots,
      }}
    >
      {children}
    </TimeslotContext.Provider>
  );
};
