import { capitalizeFirstLetter, formatDate } from '@hpx-it/react-app';
import { Box, Grid, Typography } from '@mui/material';
import { RemoteAssistContext } from 'contexts/remoteAssistContext';
import { useContext } from 'react';

const BACKGROUND_IMAGE_SRC = '/static/connecting-screen-house.png';

export const InvalidStateScreen = () => {
  const { remoteAssist } = useContext(RemoteAssistContext);

  if (!remoteAssist) {
    return <></>;
  }

  return (
    <>
      <Grid
        pt={8}
        container
        direction="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          pb={{ xs: 3, sm: 5 }}
          width={{ xs: '90%', sm: 'fit-content' }}
          textAlign="center"
        >
          <Typography fontSize={{ xs: '1.5rem', sm: '2.5rem' }} variant="h5">
            This Session Has Been {capitalizeFirstLetter(remoteAssist.status)}
          </Typography>
        </Grid>
        <Grid
          alignItems="flex-start"
          color="#9ca1b1"
          gap={2}
          bgcolor="#f9f9fb"
          width={{ xs: '90%', sm: '75%' }}
          maxWidth="500px"
          container
          py="1rem"
          px={{ xs: '1rem', sm: '1.5rem' }}
          borderRadius="4px"
          border="1px solid #cfd3df"
        >
          <Grid
            item
            pr={{ xs: 4, sm: 5 }}
            width="6px"
            fontSize="17px"
            lineHeight="1.25"
          >
            {formatDate(remoteAssist.last_status_change_at).formattedDate}
          </Grid>
          <Grid
            item
            border="2px dashed #d4eef5"
            flex="0 0 auto"
            height="60.5px"
            width="1px"
          ></Grid>
          <Grid item xs>
            <Typography
              fontSize={{ xs: '18px', sm: '20px' }}
              fontWeight="medium"
            >
              Remote Assist
            </Typography>
            <Typography
              fontSize={{ xs: '18px', sm: '20px' }}
              fontWeight="light"
            >
              {remoteAssist.title ?? remoteAssist.service_code.name}
            </Typography>
            {remoteAssist.reference && (
              <Typography>{remoteAssist.reference}</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box
        position="fixed"
        bottom={0}
        zIndex={-1}
        width="100vw"
        height={{ xs: '30vh', sm: '45vh' }}
      >
        <img
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          src={BACKGROUND_IMAGE_SRC}
          alt="house"
          width="100%"
          height="100%"
        />
      </Box>
    </>
  );
};
