import { Box, lighten } from '@mui/material';
import { ComponentProps } from 'react';

export const TOP_BAR_BACKGROUND_LIGHTEN_VALUE = 0.6;

export const getHeaderBoxStyle: () => ComponentProps<typeof Box> = () => ({
  width: '100%',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
  zIndex: 100,
  sx: {
    bgcolor: (theme) =>
      lighten(theme.palette.primary.main, TOP_BAR_BACKGROUND_LIGHTEN_VALUE),
  },
});

export const getImageContainerBoxStyle: () => ComponentProps<
  typeof Box
> = () => ({
  paddingY: '10px',
  justifyContent: 'center',
  alignItems: 'center',
});

export const getImageStyle: () => ComponentProps<'img'> = () => ({
  height: '30px',
});
