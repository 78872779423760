import 'reflect-metadata';

import './setup';

import { Theme, ThemeProvider } from '@mui/material';
import {
  DeveloperApiClientProvider,
  QueueProvider,
  RemoteAssistProvider,
  TimeslotProvider,
} from 'contexts';
import { ClientProvider } from 'contexts/clientContext';
import { Main } from 'main/Main';
import { useState } from 'react';
import { generateTheme } from 'theme';

function App() {
  const [theme, setTheme] = useState<Theme>(generateTheme());

  return (
    <ThemeProvider theme={theme}>
      <DeveloperApiClientProvider>
        <ClientProvider setTheme={setTheme}>
          <RemoteAssistProvider>
            <QueueProvider>
              <TimeslotProvider>
                <Main />
              </TimeslotProvider>
            </QueueProvider>
          </RemoteAssistProvider>
        </ClientProvider>
      </DeveloperApiClientProvider>
    </ThemeProvider>
  );
}

export default App;
