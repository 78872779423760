import { Grid } from '@mui/material';
import { Header } from 'components';
import {
  ClientContext,
  DeveloperApiClientContext,
  QueueContext,
  RemoteAssistContext,
  TimeslotContext,
} from 'contexts';
import { useContext } from 'react';
import {
  InvalidStateScreen,
  LoadingScreen,
  NotFoundScreen,
  TicketScreen,
  VideoRoomScreen,
  SunsetScreen,
  TermsScreen,
} from 'screens';

import { getMainContainerStyle } from './style';

export function Main() {
  const { loading: clientLoading } = useContext(ClientContext);
  const { remoteAssist, termsAccepted } = useContext(RemoteAssistContext);
  const { token } = useContext(DeveloperApiClientContext);
  const { ticket, loadingTickets, routeToVideoRoom } = useContext(QueueContext);
  const { numberOfTimeslots, loadingTimeslots } = useContext(TimeslotContext);

  if (!token) {
    return (
      <ScreenWrapper>
        <NotFoundScreen />
      </ScreenWrapper>
    );
  }

  if (clientLoading || loadingTickets || loadingTimeslots) {
    return (
      <ScreenWrapper>
        <LoadingScreen />
      </ScreenWrapper>
    );
  }

  if (!remoteAssist) {
    return (
      <ScreenWrapper>
        <NotFoundScreen />
      </ScreenWrapper>
    );
  }

  if (
    remoteAssist.status === 'completed' ||
    remoteAssist.status === 'canceled'
  ) {
    return (
      <ScreenWrapper>
        <InvalidStateScreen />
      </ScreenWrapper>
    );
  }

  if (
    ticket &&
    remoteAssist.schedule.type === 'scheduled' &&
    !('start_time' in remoteAssist.schedule) &&
    !routeToVideoRoom
  ) {
    const notEnoughTimeslots =
      numberOfTimeslots !== undefined && numberOfTimeslots <= 5;
    const pastClosingDate =
      new Date(Date.UTC(2025, 0, 26, 6, 0, 0, 0)) <= new Date();

    return (
      <ScreenWrapper>
        {notEnoughTimeslots || pastClosingDate ? (
          <SunsetScreen ticket={ticket} reference={remoteAssist.reference} />
        ) : (
          <TicketScreen ticket={ticket} reference={remoteAssist.reference} />
        )}
      </ScreenWrapper>
    );
  }

  if (!termsAccepted) {
    return (
      <ScreenWrapper>
        <TermsScreen />
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper>
      <VideoRoomScreen />
    </ScreenWrapper>
  );
}

const ScreenWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid {...getMainContainerStyle()}>
      <Header />
      {children}
    </Grid>
  );
};
