import { MetricSpy } from '@hpx-it/mui-wrapper';
import { metricLogger } from '@hpx-it/react-app';
import { Box, Typography, Divider, Paper } from '@mui/material';
import {
  getTicketScreenBoxStyle,
  getScreenTitleStyle,
  getReferenceStyle,
  getDividerStyle,
  getTicketPaperStyle,
  getCeasedServiceStyle,
} from './style';
import { TicketScreenFooter } from '../TicketScreen/TicketScreenFooter';
import { Ticket } from '@hpx-it/queue-client';

type SunsetScreenProps = {
  ticket: Ticket;
  reference?: string;
};

export const SunsetScreen = ({ ticket, reference }: SunsetScreenProps) => {
  return (
    <MetricSpy
      metrics={{
        logger: metricLogger,
        prefix: 'SunsetScreen',
        tags: {
          ticket_id: ticket.getTicketId(),
          user_viewable_id: ticket.getUserViewableId(),
          supply_client_id: ticket.getSupplyClientId(),
        },
      }}
    >
      <Box {...getTicketScreenBoxStyle()}>
        <Typography {...getScreenTitleStyle()}>
          Remote Assist Video Appointment
        </Typography>
        {reference && (
          <Typography {...getReferenceStyle()}>ID: {reference}</Typography>
        )}

        <Divider {...getDividerStyle()} />
        <Paper {...getTicketPaperStyle()}>
          <Typography {...getCeasedServiceStyle()}>
            Remote Assist has ceased service.
          </Typography>
          <Typography>
            We apologize for the inconvenience. Please contact Pathlight or
            Tricon directly if you need further support.
          </Typography>
        </Paper>

        <TicketScreenFooter isTicketCompleted={true} />
      </Box>
    </MetricSpy>
  );
};
