import { Box, Typography, Divider, Paper } from '@mui/material';
import { ComponentProps } from 'react';

export const getTicketScreenBoxStyle: () => ComponentProps<
  typeof Box
> = () => ({
  textAlign: 'center',
  height: 'calc(100dvh - 50px)',
  mt: 3,
  width: '80vw',
  maxWidth: '400px',
});

export const getScreenTitleStyle: () => ComponentProps<
  typeof Typography
> = () => ({
  mb: 1,
  fontWeight: 500,
  fontSize: '1.1rem',
});

export const getReferenceStyle: () => ComponentProps<
  typeof Typography
> = () => ({
  mb: 2,
});

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  orientation: 'horizontal',
  sx: {
    my: 2,
  },
});

export const getTicketPaperStyle = (): ComponentProps<typeof Paper> => ({
  elevation: 4,
  sx: {
    textAlign: 'center',
    my: 3,
    p: 3,
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0.5rem',
    opacity: 1,
    pointerEvents: 'initial',
  },
});

export const getCeasedServiceStyle = getReferenceStyle;
